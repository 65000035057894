import {Form, Input, message} from 'antd';
import {ModalContent, FormItem} from '@ra-lib/admin';
import {toLogin} from 'src/commons';
import config from 'src/commons/config-hoc';

export default config({
    modal: {
        title: 'Change Password',
        width: 500,
    },
})(function PasswordModal(props) {
    const {onOk, onCancel, ajax} = props;

    async function handleSubmit(values) {
        // alert('TODO 接口对接');
        await ajax
            .post('/api/v1/agent/user/updatePwd', {newPwd: values?.password, oldPwd: values?.oldPassword})
            .then((res) => {
                if (res?.code === 0) {
                    message.success('Modification succeeded！Please login again!');
                    toLogin();
                    onOk();
                } else {
                    message.error(res?.msg);
                }
            })
            .catch(() => []);
    }

    // const layout = {
    //     labelCol: { flex: '150px' },
    // };
    const layout = {
        labelCol: {
            xs: {span: 12},
            sm: {span: 10},
        },
        // wrapperCol: {
        //   xs: { span: 12 },
        //   sm: { span: 24 },
        // },
    };

    return (
        <Form onFinish={handleSubmit}>
            <ModalContent okHtmlType="submit" onCancel={onCancel} okText="Done" cancelText="Cancel">
                <FormItem
                    labelAlign="left"
                    {...layout}
                    type="password"
                    label="Old password"
                    name="oldPassword"
                    rules={[{required: true, message: 'input old password!'}]}
                >
                    <Input placeholder="input old password"/>
                </FormItem>
                <FormItem
                    labelAlign="left"
                    {...layout}
                    type="password"
                    label="New password"
                    name="password"
                    rules={[
                    () => ({ 
                        validator(_,pwd) {
                              let reg =/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
                              let re = new RegExp(reg)
                              if (re.test(pwd)) { 
                                return Promise.resolve()
                              } else {
                                return Promise.reject("A combination of 8-16 letters and numbers");
                              }
                        }
                     })
                ]}
                >
                    <Input placeholder="input new password" />
                </FormItem>
                <FormItem
                    {...layout}
                    labelAlign="left"
                    type="password"
                    label="Confirm new password"
                    name="rePassword"
                    dependencies={['password']}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value) {
                                    return Promise.reject(new Error('input confirm new password!'));
                                }
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        'Two input password must be consistent',
                                    ),
                                );
                            },
                        }),
                    ]}
                >
                    <Input placeholder="input confirm new password" />
                </FormItem>
            </ModalContent>
        </Form>
    );
});
