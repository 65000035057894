
        export default [{
            filePath: 'D:/代码/G/gachaAgent/src/pages/AccountWithdrawlist/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/AccountWithdrawlist/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/AccountWithdrawlist/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/accountWithdraw/list',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/affiliatorManagement/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: { title: 'Update', width: 450, top: 50 },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/affiliatorManagement/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/affiliatorManagement/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/affiliatorManagement',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/connect.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/connect.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/videoManagement/videoList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/earningsManagement/DayEcharts.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        },{
            filePath: 'D:/代码/G/gachaAgent/src/pages/earningsManagement/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Min price',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/earningsManagement/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/earningsManagement/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/earningsManagement',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/earningsManagement/MonthEcharts.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        },{
            filePath: 'D:/代码/G/gachaAgent/src/pages/earningsManagement/updateRepoPriceModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Update Fee',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/home/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/home/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/InventoryManagement/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Modification Stock quantity',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/InventoryManagement/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/InventoryManagement/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/gacha/inventoryManagement',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotAdministration/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Modification NFT ID',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotAdministration/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotAdministration/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/jackpot/administration',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotBetList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotBetList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotBetList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/jackpot/betList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotStatistics/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotStatistics/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Jeckpot/JeckpotStatistics/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/normalGacha/detailsList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Jeckpot/VideoList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Modification url',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Jeckpot/VideoList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Jeckpot/VideoList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/jackpot/videoList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/login/index-full.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/login/index-full.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/f/login',
    auth: false,
    layout: false,
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/login/index-portal.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/login/index-portal.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/p/login',
    auth: false,
    layout: false,
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/login/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/login/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/login',
    auth: false,
    layout: false,
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Market/RepoList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Min price',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Market/RepoList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Market/RepoList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/market/repoList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Market/RepoList/updateRepoPriceModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Update Fee',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Market/RepoPrice/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Market/RepoPrice/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Market/RepoPrice/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/market/repoPrice',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Market/RepoTotal/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Modification Min price',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Market/RepoTotal/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Market/RepoTotal/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/market/repoTotal',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Market/RepoTotal/updateRepoPriceModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'updateFee',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/menus/ActionEdit.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        },{
            filePath: 'D:/代码/G/gachaAgent/src/pages/menus/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/menus/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/menus',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/menus/MenuEdit.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        },{
            filePath: 'D:/代码/G/gachaAgent/src/pages/menus/MenuTableSelect.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        },{
            filePath: 'D:/代码/G/gachaAgent/src/pages/menus/SystemSelect.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        },{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/DetailsList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/DetailsList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Normal/DetailsList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/normalGacha/list',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/NormalGachaList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/NormalGachaList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Normal/NormalGachaList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/jackpot/statistics',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/OpenRateList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/OpenRateList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Normal/OpenRateList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/normalGacha/openRateList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/ProbabilityList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'set probability',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/ProbabilityList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Normal/ProbabilityList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/normalGacha/probabilityList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/StatisticalList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/StatisticalList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Normal/StatisticalList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/normalGacha/statisticalList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/VideoList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Modify url',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Normal/VideoList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Normal/VideoList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/normalGacha/videoList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/page-configs.js',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        },{
            filePath: 'D:/代码/G/gachaAgent/src/pages/paymentManagement/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/paymentManagement/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/paymentManagement/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/paymentManagement',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/role/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => (props.isEdit ? 'Editing roles' : 'Creating roles'),
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/role/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/role/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/sys/role',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/role/RoleSelectTable.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        },{
            filePath: 'D:/代码/G/gachaAgent/src/pages/SystemConfigList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Modification value',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/SystemConfigList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/SystemConfigList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/systemConfigList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/BuyList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/BuyList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Titcket/BuyList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/ticketGacha/detailsList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/OpenRateList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/OpenRateList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Titcket/OpenRateList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/ticketGacha/openRateList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/ProbabilityList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'set probability',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/ProbabilityList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Titcket/ProbabilityList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/ticketGacha/probabilityList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/StatisticalList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/StatisticalList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Titcket/StatisticalList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/ticketGacha/statisticalList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/VideoList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Modify url',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Titcket/VideoList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Titcket/VideoList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/ticketGacha/videoList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/user/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/user/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/user/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/sys/user',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/userInfo/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/userInfo/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/userInfoEdit1',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/userInfo copy/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Change user info',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/userInfo copy/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/userInfo copy/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/userInfoEdit',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/AccountBurnList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/AccountBurnList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Vip/AccountBurnList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/vipGacha/accountBurnList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/BetList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/BetList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Vip/BetList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/vipGacha/betList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/StatisticalList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/StatisticalList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Vip/StatisticalList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/vipGacha/statisticalList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/VideoList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: 'Modify url',
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/VideoList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Vip/VideoList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/vipGacha/videoList',
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/VipGachaList/EditModal.jsx',
            
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    modal: {
        title: (props) => {
            if (props?.record?.isDetail) return 'Display user';

            return props.isEdit ? 'Edit User' : 'Create user';
        },
        width: '70%',
        top: 50,
    },
},{
            filePath: 'D:/代码/G/gachaAgent/src/pages/Vip/VipGachaList/index.jsx',
            component: ()=> import('D:/代码/G/gachaAgent/src/pages/Vip/VipGachaList/index.jsx'),
            pagesPath: 'D:/代码/G/gachaAgent/src/pages',
        
    path: '/vipGacha/list',
}]
    