/* eslint-disable no-unused-vars */
import {useState} from 'react';
import {Space, Dropdown, Menu, Avatar, message} from 'antd';
import {DownOutlined, LockOutlined, LogoutOutlined} from '@ant-design/icons';
import {getColor, FullScreen, getLoginUser} from '@ra-lib/admin';
import {IS_MOBILE} from 'src/config';
import config from 'src/commons/config-hoc';
import {toLogin} from 'src/commons';
import PasswordModal from './PasswordModal';
import styles from './style.less';
import {Proxy} from 'src/components';

export default config({
    router: true,
})(function Header(props) {

    const {loginUser = {}} = props;
    const [passwordVisible, setPasswordVisible] = useState(false);

    async function handleLogout() {
        try {
            await props.ajax.post('/api/v1/agent/user/loginOut', null, {errorTip: false});
            // alert('TODO 退出登录接口！');
        } finally {
            // 无论退出成功失败，都跳转登录页面
            toLogin();
        }
    }

    // 获取列表
    const {data: { data } = {}} = props.ajax.useGet('/api/v1/agent/user/homeInfo', {}, [], {
            formatResult: (res) => {
                if(res?.code === 10000) {
                    message.warning(res?.msg)
                    setTimeout(() => {
                        toLogin()
                    }, 1000);
                }
                return {
                    data: res?.data,
                };
            },
        },
    );

    const menu = (
        <Menu>
            <Menu.Item key="modify-password" icon={<LockOutlined />} onClick={() => setPasswordVisible(true)}>
                Change Password
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" danger icon={<LogoutOutlined />} onClick={handleLogout}>
                Sign Out
            </Menu.Item>
        </Menu>
    );

    const {avatar, name = '', email = '' } = loginUser;

    return (
        <Space
            className={styles.root}
            size={16}
            style={{
                paddingRight: IS_MOBILE ? 0 : 12,
            }}
        >
            {/* <Proxy className={styles.action} /> */}

            {IS_MOBILE ? null : (
                <>
                    <div className={styles.action}>
                        <FullScreen />
                    </div>
                </>
            )}

            <Dropdown overlay={menu}>
                <div className={styles.action}>
                    {/*{avatar ? (*/}
                    {/*    <Avatar size="small" className={styles.avatar} src={avatar} />*/}
                    {/*) : (*/}
                    {/*    <Avatar size="small" className={styles.avatar} style={{backgroundColor: getColor(data?.email)}}>*/}
                    {/*        {(name[0] || '').toUpperCase()}*/}
                    {/*    </Avatar>*/}
                    {/*)}*/}
                    {IS_MOBILE ? null : (
                        <>
                            <span className={styles.userName}>{data?.email}</span>
                            <DownOutlined />
                        </>
                    )}
                </div>
            </Dropdown>
            <PasswordModal
                visible={passwordVisible}
                onCancel={() => setPasswordVisible(false)}
                onOk={() => setPasswordVisible(false)}
            />
        </Space>
    );
});
